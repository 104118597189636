export const navDetails = [
  {
    navMain: "Product",
    detailed: false,
    dropdown: true,
    external: false,
    handle: "/",
    subNav: [
      {
        id: 111,
        icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680257310/Fountainpay-V2_Assets/payment_lmcvvp.gif",
        nav: "Payment Proccessing",
        handle: "/gateway",
      },
      {
        id: 112,
        icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680257309/Fountainpay-V2_Assets/agency_kqrcin.gif",
        nav: "Agency Banking",
        handle: "/agency-banking",
      },
    ],
  },
  {
    navMain: "Pricing",
    detailed: false,
    dropdown: false,
    external: false,
    handle: "/pricing",
    subNav: [],
  },
  {
    navMain: "Documentation",
    detailed: false,
    dropdown: false,
    external: true,
    handle: "https://docs.fountainpay.ng",
    subNav: [],
  },
  {
    navMain: "Need help?",
    detailed: false,
    dropdown: false,
    external: true,
    handle: "/need-help",
    subNav: [],
  },
];

export const socialMediaDetails = [
  {
    name: "Twitter",
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680257547/Fountainpay-V2_Assets/twitter_eoaaqp.png",
    handle: "https://twitter.com/fountainpay_",
  },
  {
    name: "Linkedin",
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680257547/Fountainpay-V2_Assets/linkedin_nf6nrz.png",
    handle: "https://www.linkedin.com/company/fountain-payment-systems-solutions/",
  },
  {
    name: "Instagram",
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680257547/Fountainpay-V2_Assets/instagram_vsfqjx.png",
    handle: "https://www.instagram.com/fountainpay_/",
  },
  {
    name: "Facebook",
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680257547/Fountainpay-V2_Assets/facebook_l1grjh.png",
    handle: "",
  },
];

export const footerContents = [
  {
    id: "1",
    heading: "Products",
    width: "163px",
    details: [
      {
        id: "5",
        link: false,
        handle: "Payment processing",
        path: "/gateway",
      },
      {
        id: "6",
        link: false,
        handle: "Agency banking",
        path: "/agency-banking",
      },
    ],
  },
  {
    id: "2",
    heading: "Quick Links",
    width: "126px",
    details: [
      {
        id: "14",
        link: false,
        handle: "Pricing",
        path: "/pricing",
      },
      {
        id: "15",
        link: false,
        handle: "Blog",
        path: "/",
      },
      {
        id: "16",
        link: false,
        handle: "Documentation",
        path: "/",
      },
    ],
  },
  {
    id: "3",
    heading: "Support",
    width: "248px",
    details: [
      {
        id: "21",
        link: false,
        handle: "Contact",
        path: "/",
      },
      {
        id: "22",
        link: false,
        handle: "FAQ",
        path: "/faq",
      },
      {
        id: "23",
        link: false,
        handle: "Privacy policy",
        path: "/privacy",
      },
      // {
      //   id: "24",
      //   link: false,
      //   handle: "Terms of use",
      //   path: "/",
      // },
      {
        id: "25",
        link: false,
        handle: "Cookie policy",
        path: "/cookie",
      },
      {
        id: "26",
        link: false,
        handle: "Payment security guarantee",
        path: "/payment-security",
      },
    ],
  },
  {
    id: "4",
    heading: "Company",
    width: "105px",
    details: [
      {
        id: "27",
        link: false,
        handle: "About us",
        path: "/about",
      },
      {
        id: "28",
        link: false,
        handle: "Media kit",
        path: "/",
      },
      // {
      //   id: "29",
      //   link: false,
      //   handle: "Career",
      //   path: "/careers",
      // },
    ],
  },
];

export const coreProductDetails = [
  {
    title: "Payment Processing",
    text: "Make payment with our easy-to-use, secure and adaptable payment platform.",
    image:
      "https://res.cloudinary.com/dyozdezpg/image/upload/v1680521523/Fountainpay-V2_Assets/Frame_4265_j9h8bn.png",
    path: "/gateway/",
  },
  {
    title: "Agency Banking",
    text: "Receive and increase alternative payments options. Say goodbye to payment downtime.",
    image:
      "https://res.cloudinary.com/dyozdezpg/image/upload/v1680521524/Fountainpay-V2_Assets/Agency_Banking_t6u8hd.png",
    path: "/agency-banking/",
  },
];

export const testimonialDetails = [
  {
    image:
      "https://res.cloudinary.com/dyozdezpg/image/upload/v1681215949/Fountainpay-V2_Assets/Frame_2_mviz5a.svg",
    name: "Adeolu James",
    position: "CEO, Gold and Silver",
    testimony:
      "FountainPay has made online payments so much easier for my business. The process is seamless, the platform is user-friendly and I am impressed with their excellent customer service. Their team is always available to answer my questions and provide assistance when needed. I highly recommend them for anyone looking for a reliable payment gateway.",
  },
  {
    image:
      "https://res.cloudinary.com/dyozdezpg/image/upload/v1681215949/Fountainpay-V2_Assets/Group_2_ttzpdj.svg",
    name: "Raji Adegoke",
    position: "Store Owner",
    testimony:
      "FountainPay's security measures are top-notch, and I feel confident that my financial information is safe when using their platform. This is a huge plus for me as a business owner.",
  },
  {
    image:
      "https://res.cloudinary.com/dyozdezpg/image/upload/v1681215949/Fountainpay-V2_Assets/Group_jrkk4t.svg",
    name: "Adewunmi Adewuyi",
    position: "CEO, Ideafit",
    testimony:
      "FountainPay has helped us boost our profits and efficiency by giving us the ability to process multiple orders at once, automatically send receipts to the customers and most importantly, give us the opportunity to keep track of our finances.",
  },
  {
    image:
      "https://res.cloudinary.com/dyozdezpg/image/upload/v1681215949/Fountainpay-V2_Assets/Frame_eukcrt.svg",
    name: "Mary Adeolu",
    position: "Business Owner",
    testimony:
      "I own a store and have been using Fountainpay's POS since last year. Support has been extremely responsive to our needs, and they assist the agents in responding to our inquiries efficiently. If we open another store, FountainPay's POS will be the only one we use.",
  },
];

export const paymentProcessingDetails = [
  {
    title: "Multiple Services",
    text: "Add a range of services you provide to your business profile using the services option.",
    bg: "rgba(118, 0, 0, 0.09)",
    border: "#760000",
  },
  {
    title: "Split Bills Payment",
    text: "Create a unique payment link for seamless acceptance of payment. This feature allows you to split bills amongst your customers for a particular service.",
    bg: "rgba(7, 136, 90, 0.09)",
    border: "#000000",
  },
  {
    title: "Seamless KYC",
    text: "We have streamlined the compliance process to take you less than 5 minutes and provide you with a seamless KYC experience.",
    bg: "rgba(39, 59, 74, 0.09)",
    border: "#273B4A",
  },
  {
    title: "Create Subaccount",
    text: "Split your payments into different accounts. You can split multiple payments flows using sub-accounts, giving you a better view of your incoming and outgoing payments.",
    bg: "rgba(248, 110, 127, 0.09)",
    border: "#F86E7F",
  },
];

export const faqDetails = [
  {
    question: "Who is FountainPay?",
    answer:
      "FountainPay is an exceptional payment gateway that offers secured digital payment solutions that enable your business accept payment seamlessly.",
  },
  {
    question: "Who can use FountainPay?",
    answer:
      "FountainPay systems and solution has a wild range fit for the business owners at both start-up level or large scale, corporate firms, hospitals, schools, and any ecommerce business.",
  },
  {
    question: " How do I become a Merchant?",
    answer:
      "All you need to become a merchant on FountainPay is to click on the sign up button on the home page and fill in all the necessary details required of you, then you receive a notification in your registered email for registration confirmation and success.",
  },
  {
    question: "How secure is FountainPay?",
    answer:
      "Our FountainPay account's security and safety are our top priorities, and we important and secured measures to make sure all accounts are secure.",
  },
  {
    question: "How do I become an Agent?",
    answer: "For more information - Send an email to support@fountainpay.ng.",
  },
];

export const coreValues = [
  {
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680781947/Fountainpay-V2_Assets/Group_4052_awj1vm.svg",
    title: "Affordability",
    text: "We are committed to offering you an affordable and reliable payment option so you may boost your business earnings.",
  },
  {
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680781947/Fountainpay-V2_Assets/Group_4050_loz0ay.svg",
    title: "Custumer Focus",
    text: "We put the needs of our clients first when providing payment solution services, and we collaborate closely with them to guarantee a positive user experience.",
  },
  {
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680781948/Fountainpay-V2_Assets/Group_4264_f1m3nu.svg",
    title: "Trust",
    text: "We are trustworthy and dedicated to providing secure services, treating each and every one of our clients fairly and honestly in all of our dealings.",
  },
  {
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680781946/Fountainpay-V2_Assets/Group_4056_d2lqem.svg",
    title: "Innovation",
    text: "Our creative team is  committed to developing new features and services for our products that will increase customer satisfaction.",
  },
  {
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680781946/Fountainpay-V2_Assets/Group_4053_io0upi.svg",
    title: "Value Creation",
    text: "Delivering the greatest payment experience is a key part of FountainPay's commitment to creating value for our clients.",
  },
  {
    icon: "https://res.cloudinary.com/dyozdezpg/image/upload/v1680781948/Fountainpay-V2_Assets/Group_4265_xsvh81.svg",
    title: "Excellence",
    text: "We make a conscious effort to provide our clients with exceptional, simple, and secure payment options.",
  },
];
