import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import ScrollToTop from "./scrollToTop/ScrollToTop";
import { useEffect } from "react";
import Aos from "aos";

import "aos/dist/aos.css";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    Aos.init({
      offset: 100,
      duration: 600,
      easing: "ease-in-sine",
    });
  }, []);
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <AppRoutes />
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
